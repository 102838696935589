import { forwardRef, PropsWithChildren } from 'react';
import { Button, ButtonProps, Container, ContainerProps } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form/lib/types';
import { useResource, useWarnAboutChange } from "@refinedev/core";
import { IconArrowLeft } from '@tabler/icons-react';
import { useGoBackToList, useUpdateOrCreate } from '@components/hooks';
import { ResourceFormProvider, useResourceForm } from '@components/ui/form/ResourceForm.context';
import { useIdentity } from '@components/data/Identity.context';
import { useDidUpdate, usePrevious } from '@mantine/hooks';

type Props<T = unknown> = {
  form: UseFormReturnType<T>;
  resource?: string;
  id?: number;
  onBeforeSave?: (values: T) => T;
  onAfterSave?: (values: T) => void;
  showBackButton?: boolean;
  disableRedirect?: boolean;
  mutationMeta?: {
    headers?: {}
  }
} & Omit<ContainerProps, 'id'>;

export const setFormErrorsFromResponse = (response, form: UseFormReturnType<any>) => {
  Object.entries(response.data?.errors).forEach(([key, value]) => {
    key = key.replace(/\.\d+$/, '');
    form.setFieldError(key, value[0]);
  });
}

export const ResourceForm = ({
  form, onBeforeSave, onAfterSave, showBackButton = true, children, id: overrideResourceId, resource: overrideResourceName, disableRedirect = false,
  mutationMeta = {}, ...props
}: PropsWithChildren<Props>) => {
  const { id, resource } = useResource();
  const { goBack, canGoBack } = useGoBackToList();
  const { setWarnWhen } = useWarnAboutChange();
  const { mutate, isLoading } = useUpdateOrCreate();
  const { identity } = useIdentity();
  const previousOwner = usePrevious(identity?.owner);

  useDidUpdate(() => {
    setWarnWhen(form.isDirty() && form.isTouched());
  }, [form.values]);

  // Used to safeguard resources when account switching
  useDidUpdate(() => {
    if (previousOwner && identity?.owner?.id !== previousOwner.id) {
      if (canGoBack(resource.name)) {
        goBack(resource.name);
      }
    }
  }, [identity?.owner]);

  const handleSubmit = async (values) => {
    setWarnWhen(false);

    if (onBeforeSave) {
      values = await onBeforeSave(values);
    }

    mutate({
      resource: overrideResourceName || resource.name,
      id: overrideResourceId || id,
      values,
      meta: mutationMeta,
    }, {
      onSuccess: ({ data }) => {
        onAfterSave && onAfterSave(data);
        if (canGoBack(resource.name) && !disableRedirect) {
          goBack(resource.name);
        } else {
          form.setInitialValues(data);
          form.reset();
        }
      },
      onError: ({ response }) => {
        setFormErrorsFromResponse(response, form);
      },
    });
  };

  return <Container size="sm" px={0} {...props}>
    { showBackButton && <Button
        leftSection={<IconArrowLeft size={18} />}
        size="sm"
        variant="subtle"
        onClick={() => goBack(resource.name)}
        mb="xs"
      >Back</Button>
    }

    <ResourceFormProvider form={form}
                          isSaving={isLoading}
                          submit={handleSubmit}
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        { children }
      </form>
    </ResourceFormProvider>
  </Container>;
};

ResourceForm.CancelButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { resource } = useResource();
  const { goBack } = useGoBackToList();

  return <Button variant="light"
                 color="gray"
                 onClick={() => goBack(resource.name)}
                 ref={ref}
                 {...props}
  >Cancel</Button>
});

ResourceForm.SubmitButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { id } = useResource();
  const { isSaving } = useResourceForm();

  return <Button type="submit"
                 variant="light"
                 loading={isSaving}
                 ref={ref}
                 {...props}
  >{ id ? 'Update' : 'Create' }</Button>
});

ResourceForm.UpdateButton = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { isSaving } = useResourceForm();

  return <Button type="submit"
                 variant="light"
                 loading={isSaving}
                 ref={ref}
                 {...props}
  >Update</Button>
});
