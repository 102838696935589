import { ActionIcon, Grid, Group, Loader, MultiSelect, Select, TextInput, Text, Anchor } from '@mantine/core';
import { IconExternalLink, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import type { GetInputPropsReturnType } from '@mantine/form/lib/types';
import { useApiUrl, useCustom } from '@refinedev/core';
import { IFilterOptionItem } from '@interfaces';

type Props = {
  fields: {
    column: GetInputPropsReturnType;
    condition: GetInputPropsReturnType;
    value: GetInputPropsReturnType;
  }
  onRemove: () => void;
};

const COLUMNS = [
  { 'field': 'tag_type_id', 'label': 'Type', 'type': 'foreignId' },
  { 'field': 'asset_id', 'label': 'Asset ID', 'type': 'text' },
  { 'field': 'identifier', 'label': 'Tag ID', 'type': 'text' },
  { 'field': 'name', 'label': 'Name', 'type': 'text' },
  { 'field': 'account_name', 'label': 'Account Name', 'type': 'text' },
  { 'field': 'description', 'label': 'Description', 'type': 'text' },
  { 'field': 'location_id', 'label': 'Location ID', 'type': 'text' },
  { 'field': 'location_name', 'label': 'Location Name', 'type': 'text' },
];

const CONDITIONS = {
  'foreignId': Object.entries({
    'in': 'is',
    'notIn': 'is not',
    'empty': 'is empty',
    'notEmpty': 'is not empty',
  }),
  'text': Object.entries({
    'in': 'is',
    'notIn': 'is not',
    'empty': 'is empty',
    'notEmpty': 'is not empty',
    'like': 'contains',
    'notLike': "doesn't contain",
    'like:start': 'starts with',
    'like:end': 'ends with',
    'expr': 'expression',
    'expr:not': 'not expression',
  }),
};

export const TagGroupRule = ({ fields, onRemove }: Props) => {
  const [conditionOptions, setConditionOptions] = useState<any>();

  const apiUrl = useApiUrl();
  const { data, isFetching, refetch } = useCustom<IFilterOptionItem[] | string[]>({
    url: `${apiUrl}/tag_group/filter/values?column=${fields.column.value}`,
    method: 'get',
    queryOptions: {
      enabled: false,
      refetchOnMount: false,
    }
  });

  const isMultiSelect = ['in', 'notIn', 'empty', 'notEmpty', 'expr', 'expr:not'].includes(fields.condition.value)

  useEffect(() => {
    if (['in', 'notIn'].includes(fields.condition.value) && !data?.data) {
      refetch();
    }
  }, []);

  useEffect(() => {
    const selected = COLUMNS.find(v => v.field === fields.column.value);

    setConditionOptions(
      selected ? CONDITIONS[selected.type].map(([value, label]) => ({ label, value })) : []
    );
  }, [fields.column.value]);

  return <Group wrap="nowrap" gap={5} align="start">
    <Grid gutter={5} w="100%">
      <Grid.Col span={{ xs: 6, sm: isMultiSelect ? 6 : 4 }}>
        <Select placeholder="Column"
                data={COLUMNS.map(v => ({ label: v.label, value: v.field }))}
                {...fields.column}
                onChange={(value) => {
                  fields.column.onChange(value);

                  setTimeout(() => refetch(), 100);
                }}
                inputWrapperOrder={['input']}
        />
      </Grid.Col>

      <Grid.Col span={{ xs: 6, sm: isMultiSelect ? 6 : 4 }}>
        <Select placeholder={!fields.column.value ? 'Select a Column' : 'Condition'}
                data={conditionOptions}
                disabled={!fields.column.value}
                {...fields.condition}
                onChange={(value) => {
                  fields.condition.onChange(value);
                  fields.value.onChange('');
                }}
                inputWrapperOrder={['input']}
        />
      </Grid.Col>

      { ['expr', 'expr:not'].includes(fields.condition.value) && <>
        <Grid.Col span={12}>
          <TextInput placeholder="Value"
                     {...fields.value}
                     value={!Array.isArray(fields.value.value) ? fields.value.value ?? '' : ''}
                     rightSection={isFetching && <Loader size="xs" />}
          />
          { !fields.value.error && <>
            <Anchor size="xs"
                    mt={5}
                    style={{ display: 'block', verticalAlign: 'text-top' }}
                    target="_blank"
                    href="https://dev.mysql.com/doc/refman/8.4/en/regexp.html#regexp-syntax">
              Learn about writing expressions <IconExternalLink size={13} stroke={1.5} />
            </Anchor>
          </>}
        </Grid.Col>
      </>}

      { ['like', 'notLike', 'like:start', 'like:end'].includes(fields.condition.value) && <>
        <Grid.Col span={{ xs: 12, sm: 4 }}>
          <TextInput placeholder="Value"
                     {...fields.value}
                     value={!Array.isArray(fields.value.value) ? fields.value.value ?? '' : ''}
                     rightSection={isFetching && <Loader size="xs" />}
                     inputWrapperOrder={['input']}
          />
        </Grid.Col>
      </>}

      { ['in', 'notIn'].includes(fields.condition.value) && <>
        <Grid.Col span={12}>
          <MultiSelect placeholder="Value"
                       searchable
                       {...fields.value}
                       value={Array.isArray(fields.value.value) ? fields.value.value : []}
                       rightSection={isFetching && <Loader size="xs" />}
                       data={data?.data ?? []}
                       inputWrapperOrder={['input']}
          />
        </Grid.Col>
      </>}

      { !fields.condition.value && <>
        <Grid.Col span={4}>
          <Select placeholder={!fields.condition.value ? 'Select a Condition' : 'Value'}
                  rightSection={isFetching && <Loader size="xs" />}
                  disabled
                  inputWrapperOrder={['input']}
          />
        </Grid.Col>
      </>}
    </Grid>

    <ActionIcon variant="default" mt={4} onClick={onRemove}>
      <IconX size={16}/>
    </ActionIcon>
  </Group>;
}
