export const default_time_options = [
  {label: '12:00am', value: '00:00'}, {label: '12:15am', value: '00:15'}, {label: '12:30am', value: '00:30'}, {label: '12:45am', value: '00:45'},
  {label: '01:00am', value: '01:00'}, {label: '01:15am', value: '01:15'}, {label: '01:30am', value: '01:30'}, {label: '01:45am', value: '01:45'},
  {label: '02:00am', value: '02:00'}, {label: '02:15am', value: '02:15'}, {label: '02:30am', value: '02:30'}, {label: '02:45am', value: '02:45'},
  {label: '03:00am', value: '03:00'}, {label: '03:15am', value: '03:15'}, {label: '03:30am', value: '03:30'}, {label: '03:45am', value: '03:45'},
  {label: '04:00am', value: '04:00'}, {label: '04:15am', value: '04:15'}, {label: '04:30am', value: '04:30'}, {label: '04:45am', value: '04:45'},
  {label: '05:00am', value: '05:00'}, {label: '05:15am', value: '05:15'}, {label: '05:30am', value: '05:30'}, {label: '05:45am', value: '05:45'},
  {label: '06:00am', value: '06:00'}, {label: '06:15am', value: '06:15'}, {label: '06:30am', value: '06:30'}, {label: '06:45am', value: '06:45'},
  {label: '07:00am', value: '07:00'}, {label: '07:15am', value: '07:15'}, {label: '07:30am', value: '07:30'}, {label: '07:45am', value: '07:45'},
  {label: '08:00am', value: '08:00'}, {label: '08:15am', value: '08:15'}, {label: '08:30am', value: '08:30'}, {label: '08:45am', value: '08:45'},
  {label: '09:00am', value: '09:00'}, {label: '09:15am', value: '09:15'}, {label: '09:30am', value: '09:30'}, {label: '09:45am', value: '09:45'},
  {label: '10:00am', value: '10:00'}, {label: '10:15am', value: '10:15'}, {label: '10:30am', value: '10:30'}, {label: '10:45am', value: '10:45'},
  {label: '11:00am', value: '11:00'}, {label: '11:15am', value: '11:15'}, {label: '11:30am', value: '11:30'}, {label: '11:45am', value: '11:45'},
  {label: '12:00pm', value: '12:00'}, {label: '12:15pm', value: '12:15'}, {label: '12:30pm', value: '12:30'}, {label: '12:45pm', value: '12:45'},
  {label: '01:00pm', value: '13:00'}, {label: '01:15pm', value: '13:15'}, {label: '01:30pm', value: '13:30'}, {label: '01:45pm', value: '13:45'},
  {label: '02:00pm', value: '14:00'}, {label: '02:15pm', value: '14:15'}, {label: '02:30pm', value: '14:30'}, {label: '02:45pm', value: '14:45'},
  {label: '03:00pm', value: '15:00'}, {label: '03:15pm', value: '15:15'}, {label: '03:30pm', value: '15:30'}, {label: '03:45pm', value: '15:45'},
  {label: '04:00pm', value: '16:00'}, {label: '04:15pm', value: '16:15'}, {label: '04:30pm', value: '16:30'}, {label: '04:45pm', value: '16:45'},
  {label: '05:00pm', value: '17:00'}, {label: '05:15pm', value: '17:15'}, {label: '05:30pm', value: '17:30'}, {label: '05:45pm', value: '17:45'},
  {label: '06:00pm', value: '18:00'}, {label: '06:15pm', value: '18:15'}, {label: '06:30pm', value: '18:30'}, {label: '06:45pm', value: '18:45'},
  {label: '07:00pm', value: '19:00'}, {label: '07:15pm', value: '19:15'}, {label: '07:30pm', value: '19:30'}, {label: '07:45pm', value: '19:45'},
  {label: '08:00pm', value: '20:00'}, {label: '08:15pm', value: '20:15'}, {label: '08:30pm', value: '20:30'}, {label: '08:45pm', value: '20:45'},
  {label: '09:00pm', value: '21:00'}, {label: '09:15pm', value: '21:15'}, {label: '09:30pm', value: '21:30'}, {label: '09:45pm', value: '21:45'},
  {label: '10:00pm', value: '22:00'}, {label: '10:15pm', value: '22:15'}, {label: '10:30pm', value: '22:30'}, {label: '10:45pm', value: '22:45'},
  {label: '11:00pm', value: '23:00'}, {label: '11:15pm', value: '23:15'}, {label: '11:30pm', value: '23:30'}, {label: '11:45pm', value: '23:45'},
];


export const filterAvailableFromTimeOptions = (slots, index) => {
  const previous = slots[index - 1];

  if (!previous) {
    return default_time_options;
  }

  const previousIndex = default_time_options.findIndex(o => o.value === previous.to);

  return default_time_options.filter((_o, i) => i >= previousIndex);
}

export const filterAvailableToTimeOptions = (slot) => {
  const fromIndex = default_time_options.findIndex(o => o.value === slot.from);

  return default_time_options.filter((_o, i) => i > fromIndex);
}
