import { EuiBasicTableColumn } from '@elastic/eui';
import { IHelpListItem } from '@interfaces';
import { StatusSelect } from '@components/ui/form/StatusSelect';
import { StatusIndicator } from '@components/ui/StatusIndicator';
import { ResourceItem } from '@components/data/ResourceItem';
import { DateTime } from '@components/ui/DateTime';
import { CustomerPanelTrigger } from '@components/ui/CustomerPanelTrigger';
import { ActionMenu } from '@components/table/actions/ActionMenu';
import { CreateRequestAction } from '@components/table/actions/CreateRequestAction';
import { EditRequestAction } from '@components/table/actions/EditRequestAction';
import { DeleteRequestLinkAction } from '@components/table/actions/DeleteRequestLinkAction';

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'customer_name',
    name: 'Name',
    width: '220',
    sortable: true,
    truncateText: true,
    render: (_name, record) => <CustomerPanelTrigger customer={record} />,
  },
  {
    field: 'id',
    name: 'ID',
    truncateText: true,
    sortable: true,
  },
  {
    field: 'request_type',
    name: 'Type',
    truncateText: true,
    sortable: true,
  },
  {
    field: 'tag_type',
    name: 'Equipment',
    truncateText: true,
    sortable: true,
    render: (type, item) => type ?? item.machine_type ?? '—'
  },
  {
    field: 'created_at',
    name: 'Submitted',
    width: '165',
    sortable: true,
    dataType: 'date',
    render: (date) => <DateTime value={date} />
  },
  {
    field: 'status',
    name: 'Status',
    sortable: true,
    width: '175',
    render: (status, item) => {
      return <ResourceItem resource="help" id={item.id}>
        {({ onUpdate }) => <>
          <StatusSelect value={status}
                        onChange={(status) => onUpdate({ status })}
                        options={[
                          { label: 'Open', value: 'Open' },
                          { label: 'In Progress', value: 'In Progress' },
                          { label: 'Closed', value: 'Closed' },
                        ]} />
        </>}
      </ResourceItem>;
    }
  },
  {
    field: 'refund_status',
    name: 'Refund',
    width: '130',
    sortable: true,
    render: (status) => <StatusIndicator status={status} />,
  },
  {
    name: '',
    width: '40',
    actions: [
      {
        render: (record) => <ActionMenu id={record.id}>
          <EditRequestAction type="help" label="Edit help" id={record.id} />

          { record.refund_id && <EditRequestAction type="refund" label="Edit refund" id={record.refund_id} disabled={!['Pending', 'Draft', 'Failed', 'In Review'].includes(record.refund_status)} />}
          { !record.refund_id && <CreateRequestAction type="refund" label="Create refund" />}

          { !record.refund_id && !record.request_link_id && <CreateRequestAction type="link" label="Send request link" />}
          { !record.refund_id && record.request_link_id && <DeleteRequestLinkAction id={record.request_link_id} />}
        </ActionMenu>
      },
    ]
  },
] as EuiBasicTableColumn<IHelpListItem>[];
