import { EuiBasicTableColumn } from '@elastic/eui';
import { IVoiceMessageSettingListItem } from '@interfaces';
import { ResourceItem } from '@components/data/ResourceItem';
import { StatusSelect } from '@components/ui/form/StatusSelect';
import { Group, Text } from '@mantine/core';
import { EditableText } from '@components/ui/form/EditableText';

const settingTypeText = (handler: string) => <>
  {handler === 'unanswered' && <Text size="sm" c="dimmed">Go unanswered</Text>}
  {handler === 'answered' && <Text size="sm" c="dimmed">Play message and hang-up</Text>}
  {handler === 'forwarded' && <Text size="sm" c="dimmed">Forward calls</Text>}
</>

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'phone_number',
    width: '140',
    name: 'Number',
  },
  {
    field: 'description',
    name: 'Description',
    render: (description, item) => {
      return <ResourceItem resource="voice_message/settings" id={item.id}>
        {({ onUpdate, isLoading }) => <>
          <EditableText text={description}
                        isLoading={isLoading}
                        onChange={(value) => onUpdate({ description: value })}
                        inputProps={{
                          maxLength: 150
                        }}
          />
        </>}
      </ResourceItem>;
    }
  },
  {
    field: 'type',
    name: 'Setting',
    render: (type, item) => {
      return <ResourceItem resource="voice_message/settings" id={item.id}>
        {({ onUpdate }) => <Group gap="xs" wrap="nowrap">
          <StatusSelect value={type}
                        w={130}
                        onChange={(type) => onUpdate({ type })}
                        options={[
                          { label: 'Inactive', value: 'Inactive' },
                          { label: 'Active', value: 'Active' },
                          { label: 'Schedule', value: 'Schedule' },
                        ]} />

          { type === 'Active' && settingTypeText(item.active_handler)}
          { type === 'Inactive' && settingTypeText(item.inactive_handler)}
        </Group>}
      </ResourceItem>;
    }
  },
] as EuiBasicTableColumn<IVoiceMessageSettingListItem>[];
