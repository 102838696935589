import { useEffect, useRef } from 'react';
import {
  Box, Button,
  Card,
  Divider,
  FileInput,
  Grid,
  Group,
  Input,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title
} from '@mantine/core';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import defaultVoiceActors from './default_voice_actors';
import { resolveAssetUrl } from '@libraries/helpers';
import { IconUpload } from '@tabler/icons-react';
import { IVoiceMessageSettingRecord } from '@interfaces';
import { PhoneInput } from '@components/ui/PhoneInput/PhoneInput';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { usePrevious } from '@mantine/hooks';

type Props = {
  type: 'active_settings' | 'inactive_settings';
}

export const VoiceMessageHandlerForm = ({ type }: Props) => {
  const { form } = useResourceForm<IVoiceMessageSettingRecord>();
  const audioRef = useRef<HTMLAudioElement>(null);

  const settings = form.values[type];
  const previousValue = usePrevious(settings.answered.prompt_audio_path);

  useEffect(() => {
    if (
      typeof settings.answered.prompt_audio_path === 'string' &&
      typeof previousValue === 'string' &&
      previousValue !== settings.answered.prompt_audio_path
    ) {
      setTimeout(() => {
        audioRef.current?.load();
      }, 500);
    }
  }, [settings.answered.prompt_audio_path]);

  const AnsweredSettings = () => <Stack>
    <Box>
      <SegmentedControl
        color="blue"
        data={[
          { label: 'Use Text to Voice', value: 'text' },
          { label: 'Use audio file', value: 'file' },
        ]}
        {...form.getInputProps(`${type}.answered.prompt_type`)}
      />
    </Box>

    { settings.answered.prompt_type === 'text' && <>
      <Text size="sm">Enter text for your message prompt. Text will be converted to voice and played to the caller.</Text>

      <Textarea minRows={9}
                autosize
                {...form.getInputProps(`${type}.answered.voice_script`)} />

      <Select
        label="Reader Voice"
        placeholder="Select a voice ..."
        description="The selected voice will be used to read your message prompt."
        data={
          defaultVoiceActors.map((actor) => ({
            value: `${actor.voice_name}|${actor.language_id}`,
            label: `${actor.display_name} (${actor.language_locale} ${actor.gender})`
          }))
        }
        {...form.getInputProps(`${type}.answered.voice_actor`)}
      />
    </>}

    { settings.answered.prompt_type === 'file' && <>
      {/*If prompt_audio_path is set, show the file in an audio player.*/}
      { settings.answered.prompt_audio_path && <>
        <Text size="sm">Currently used audio file.</Text>
        <audio id="voice_message" controls ref={audioRef}>
          <source src={resolveAssetUrl(settings.answered.prompt_audio_path)} />
        </audio>
      </>}

      <Text size="sm">Upload an audio file to play instead of using Text to Speech.</Text>

      <FileInput
        placeholder="Select audio file..."
        description="Supported audio files: .mp3, .wav, .aiff."
        accept="audio/mpeg,audio/wav, audio/wave, audio/aiff, audio/x-aiff, audio/x-wav, audio/x-aifc, audio/x-gsm, audio/gsm, audio/ulaw"
        leftSection={<IconUpload size={14} />}
        {...form.getInputProps(`${type}.answered.prompt_audio_path`)}
      />
    </>}
  </Stack>;

  const ForwardedSettings = () => <Stack>
    <Group>
      <PhoneInput label="Phone no."
                  { ...form.getInputProps(`${type}.forwarded.phone_number`) } />

      <TextInput label="Extension (optional)"
                 styles={{input: {width: 85}}}
                 maxLength={6}
                 { ...form.getInputProps(`${type}.forwarded.extension`) }/>
    </Group>

    <Input.Wrapper label="Extension dialing delay (optional)">
      <Group gap="sm">
        <Select w={85}
                data={['0', '0.5', '1.0', '1.5', '2.0', '2.5', '3.0', '3.5', '4.0', '4.5', '5.0']}
                {...form.getInputProps(`${type}.forwarded.delay`)}
        />
        <Text>seconds</Text>
      </Group>
    </Input.Wrapper>
  </Stack>;

  return <Grid>
    <Grid.Col span={{ lg: 6 }}>
      <Stack>
        <Select label="Call Handling"
                description="Select action for handling incoming calls"
                data={[
                  {value: 'unanswered', label: 'Go unanswered'},
                  {value: 'answered', label: 'Play message and hang-up'},
                  {value: 'forwarded', label: 'Forward calls'},
                ]}
                {...form.getInputProps(`${type}.handler`)}
        />

        { settings.handler === 'answered' && <>
          { AnsweredSettings() }
        </> }

        { settings.handler === 'forwarded' && <>
          { ForwardedSettings() }
        </> }

        <Divider />

        <Group justify="right">
          <Button variant="light" color="gray" onClick={() => form.reset()}>Cancel</Button>
          <ResourceForm.UpdateButton />
        </Group>
      </Stack>
    </Grid.Col>
    <Grid.Col span={{ lg: 6 }}>
      <Card bg="blue.0" radius="sm" mb="sm">
        <Stack gap="sm">
          <Text size="sm">[Configuration helper text goes here.]</Text>
          <Text size="sm">Configure how Zippy Voice handles inbound voice calls when inactive.</Text>

          <Box>
            <Title order={6}>Go unanswered (default)</Title>
            <Text size="sm">Zippy Voice does nothing and calls will go unanswered.</Text>
          </Box>

          <Box>
            <Title order={6}>Play message and hang-up</Title>
            <Text size="sm">Zippy will answer the call and play the message in this section before hanging-up.</Text>
          </Box>

          <Box>
            <Title order={6}>Forward calls</Title>
            <Text size="sm">Zippy will forward calls using the configuration settings in this section.</Text>
          </Box>
        </Stack>
      </Card>
    </Grid.Col>
  </Grid>;
}
