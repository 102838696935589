import { useState } from 'react';
import { CollapsableCard, LanguageBadge } from '@components/ui';
import {
  Divider,
  Grid,
  Group,
  SegmentedControl,
  Text,
  TextInput,
  Button,
  Stack,
  Box,
  Checkbox,
  Badge,
  Alert, Tooltip
} from '@mantine/core';
import { IconAlertCircle, IconCalendar, IconCalendarTime } from '@tabler/icons-react';
import { useInvalidate, useResource, useUpdate } from '@refinedev/core';
import { IRefundRecord } from '@interfaces';
import { useTabsContext } from '@components/ui/tabs/Tabs.context';
import { DateTime } from '@components/ui/DateTime';
import { Feature, useFeature } from 'flagged';
import { useIdentity } from '@components/data/Identity.context';
import { RefundAmountInput } from './RefundAmountInput';
import { RefundRequestFormProvider, useRefundRequestForm } from './RefundRequestForm.context';
import * as Yup from 'yup';
import { yupResolver } from '@mantine/form';
import { RefundNotificationPreview } from '../../../ui/notificationPreviewers/RefundNotificationPreview';
import moment from 'moment-timezone';
import { useDidUpdate } from '@mantine/hooks';
import { TextareaAutosize } from '@components/ui/TextareaAutosize';

interface Props {
  refund: IRefundRecord;
}

export const RefundRequestCard = ({ refund }: Props) => {
  const tabCtx = useTabsContext();
  const { identity, wallet, refreshWallet } = useIdentity();
  const invalidate = useInvalidate();
  const { mutate: updateRefund, isLoading } = useUpdate();
  const { mutate: updateHelp } = useUpdate();
  const [shouldCloseHelp, setShouldCloseHelp] = useState(false);
  const { resource } = useResource();

  const hasWallet = useFeature('wallet');

  const maxRefundAmount = identity.owner?.settings['refunds_max-operator-amount'] ?? 20;

  const schema = Yup.object().shape({
    amount: Yup.number()
      .transform(v => isNaN(v) ? null : v)
      .min(0.01, 'Amount must be more than $0')
      .max(maxRefundAmount, `Amount must be equal or less than $${maxRefundAmount}`)
      .required('Enter an amount'),
    status: Yup.string().notOneOf(['Pending']).required()
  });

  const initialValues = (data = refund) => ({
    status: data.status,
    amount: Number(data.amount),
    approved_decision_note: data.status === 'Approved' ? (data.decision_note || '') : '',
    declined_decision_note: data.status === 'Declined' ? (data.decision_note || '') : '',
  });

  const form = useRefundRequestForm({
    validate: yupResolver(schema),
    validateInputOnChange: true,
    initialValues: initialValues as any,
  });

  const handleSubmit = (values) => {
    updateRefund({
      resource: 'refund',
      id: refund.id,
      values: {
        status: values.status,
        decision_note: values.status === 'Approved' ? values.approved_decision_note : values.declined_decision_note,
      }
    }, {
      onSuccess: () => {
        invalidate({ resource: resource.name, invalidates: ['list'] });
        refreshWallet();
      }
    });

    if (shouldCloseHelp) {
      updateHelp({ resource: 'help', id: refund.request?.help?.id, values: { status: 'Closed' }, successNotification: false });
    }
  };

  useDidUpdate(() => {
    form.setInitialValues(initialValues());
    form.reset();
  }, [refund]);

  const isRestricted = refund.status === 'Draft' || refund.status === 'Expired';
  const allowedStatues = ['PayRange', 'External'].includes(refund.method) ? ['In Review', 'Completed'] : ['In Review', 'Approved', 'Declined'];

  const canApproveUsingWallet = refund.meta.is_platform_disbursed && hasWallet ? (wallet.meta.credit > 0 || form.values.amount < wallet.balance) : true;
  const canApprove = form.values.status === 'Approved' ? canApproveUsingWallet : true;
  const canSubmit = form.isValid() && form.isDirty() && canApprove && (refund.meta.is_editable || refund.meta.can_status_change) && Object.keys(form.errors).length === 0;

  return <CollapsableCard
    label="Refund request"
    collapsable={false}
    rightSection={
      <Tooltip label="Reference for this refund request">
        <Badge color="gray" variant="outline">{ refund.reference }</Badge>
      </Tooltip>
    }
  >
    <RefundRequestFormProvider form={form}>
      <Grid gutter="xs">
        <Grid.Col span={{ xs: 6 }}>
          <Stack gap="xs">
            <TextInput label="Method" value={refund.refund_name ?? '—'} readOnly />
            <RefundAmountInput refund={refund} />
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ xs: 6 }}>
          <Stack gap="xs">
            <TextInput label="Submitted"
                       value={moment(refund.completed_at || refund.created_at).format('D MMM \'YY h:mma')}
                       readOnly
                       leftSection={<IconCalendarTime />} />

            { refund.meta.approx_transaction_date && <>
              <TextInput label="Transaction"
                         value={moment(refund.meta.approx_transaction_date).format('D MMM \'YY') + (refund.days_since_payment >= 3 && !refund.purchased_at ? ' or before' : '')}
                         readOnly
                         leftSection={<IconCalendar />} />
            </>}
          </Stack>
        </Grid.Col>

        { !isRestricted && <Grid.Col span={{ xs: 12 }}>
          <Feature name="refund.disbursement">
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Stack align="start" gap="sm">
                { refund.meta.can_status_change && <>
                  <SegmentedControl mt={6}
                                    data={allowedStatues}
                                    styles={(theme) => ({ root: { backgroundColor: theme.white } })}
                                    transitionDuration={0}
                                    {...form.getInputProps('status')}
                                    color={ ['Approved', 'Completed'].includes(form.values.status) ? 'green' : form.values.status == 'Declined' ? 'red' : form.values.status == 'In Review' ? 'blue' : null }  />
                </>}

                { (form.values.status === 'Declined' && (form.values.declined_decision_note || identity?.owner?.settings['request_sms_send-refund-declined'])) && <Box w="100%">
                  <Group gap={5} mb={8}>
                    <Text fw={500}>Reason for declining (Optional)</Text>
                    <LanguageBadge locale={refund.request.customer_locale} />
                  </Group>

                  <RefundNotificationPreview refund={refund}>
                    <TextareaAutosize w="100%"
                              readOnly={!refund.meta.is_editable}
                              maxLength={459}
                              mb={5}
                              minRows={3}
                              {...form.getInputProps('declined_decision_note')} />
                  </RefundNotificationPreview>
                  <Text size="xs" c="dimmed" style={{ lineHeight: 1.2 }}>Enter a brief message to include with the declined notification sent to customer.</Text>
                </Box>}

                { (
                  form.values.status === 'Approved' &&
                  (form.values.approved_decision_note || identity?.owner?.settings['request_sms_send-refund-approved'])
                ) && <Box w="100%">
                  <Group gap={5} mb={8}>
                    <Text fw={500}>Reason for approving (Optional)</Text>
                    <LanguageBadge locale={refund.request.customer_locale} />
                  </Group>

                  <RefundNotificationPreview refund={refund}>
                    <TextareaAutosize w="100%"
                              readOnly={!refund.meta.is_editable}
                              maxLength={459}
                              mb={5}
                              minRows={3}
                              {...form.getInputProps('approved_decision_note')} />
                  </RefundNotificationPreview>
                  <Text size="xs" c="dimmed" style={{ lineHeight: 1.2 }}>Enter a brief message to include with the approved notification sent to customer.</Text>
                </Box>}

                { form.values.status === 'In Review' && <Box w="100%" mt={-5}>
                  <Text size="xs" c="dimmed" style={{ lineHeight: 1.2 }}>Adding a note may be useful in explaining why this refund is in review.</Text>
                </Box>}
              </Stack>

              <Divider my="md" />

              { !refund.meta.is_platform_disbursed && <>
                <Text fw="600">
                  NOTE: This is not an automated refund. You are responsible to action the refund process for this request ({refund.refund_name}).
                </Text>
              </>}

              { refund.meta.can_status_change && <>
                { canApprove && <>
                  { refund.meta.is_platform_disbursed && form.values.status === 'Approved' && <>
                    { hasWallet && <Text>
                      If approved, I agree for funds available in my ZippyWallet to be used to process this refund, including the amount plus any associated fees or costs.
                    </Text>}

                    { !hasWallet && <Text>
                      I agree to reimburse 6L Inc for approved refund amounts issued via online methods plus any
                      fees incurred in processing the payment request.
                    </Text>}
                  </>}

                  { refund.request.help?.status === 'Open' && <Group justify="right" mt="lg">
                    <Checkbox label="Close help request" checked={shouldCloseHelp} onChange={(e) => setShouldCloseHelp(e.currentTarget.checked)} />
                  </Group>}
                </>}

                { !canApprove && <>
                  <Alert icon={<IconAlertCircle size="1rem" />} variant="filled" color="red" p="xs">
                    Insufficient funds. Add more funds to your Wallet to process.
                  </Alert>
                </>}

                <Group justify="right" mt="lg">
                  <Button type="submit" disabled={!canSubmit || isLoading} loading={isLoading}>
                    { refund.meta.is_platform_disbursed && form.values.status === 'Approved' ? 'Accept and process' : 'Update status'}
                  </Button>
                </Group>
              </>}

              {
                refund.decision_at && <>
                  <Group justify="right" mt="md">
                    <Text size="sm">{refund.status} at <DateTime value={refund.decision_at} />.</Text>
                    <Button variant="default" size="xs" onClick={() => tabCtx.onTabChange('refund_logs')}>View logs</Button>
                  </Group>
                </>
              }
            </form>
          </Feature>
        </Grid.Col>}
      </Grid>
    </RefundRequestFormProvider>
  </CollapsableCard>;
}
