import { IResourceComponentsProps, useApiUrl, useCustomMutation } from '@refinedev/core';
import {
  Card, Center, Divider,
  Group, Loader, SimpleGrid,
  Stack, Switch,
  Text, TextInput,
  Title,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { ResourceForm, setFormErrorsFromResponse } from '@components/ui/form/ResourceForm';
import { TagGroupRules } from './form/TagGroupRules';
import { ITagGroupResponse } from '@interfaces';
import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { Tabs } from '@components/ui/tabs/Tabs';
import { CallOut } from '@components/ui/CallOut';
import { TagGroupSettings } from './form/TagGroupSettings';
import { useDebouncedCallback } from '@mantine/hooks';

export function TagGroupEdit({ initialData: record }: IResourceComponentsProps<ITagGroupResponse>) {
  const { mutate, data: matches, isLoading, reset } = useCustomMutation();

  const apiUrl = useApiUrl();

  const ruleSchema = {
    column: Yup.string().required(),
    condition: Yup.string().when('column', {
      is: v => !!v,
      then: (schema) => schema.required(),
    }),
    value: Yup.mixed().nullable().when('condition', ([cond], schema) => {
      if (['in', 'notIn'].includes(cond)) {
        return Yup.array().min(1).required();
      } else if (['like', 'notLike', 'like:start', 'like:end'].includes(cond)) {
        return Yup.string().required();
      } else if (['expr'].includes(cond)) {
        return Yup.string().required('Expression can not be empty');
      }

      return schema.optional();
    }),
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('Enter a name'),
    rules: Yup.object().shape({
      rules: Yup.array().of(Yup.object().when({
        is: v => !v.operator,
        then: (schema) => schema.shape({
          ...ruleSchema
        }),
        otherwise: (schema) => schema.shape({
          rules: Yup.array().of(Yup.object().shape({
            ...ruleSchema
          })),
        }),
      })).min(1).required(),
    }),
    is_active: Yup.bool(),
    settings: Yup.object({
      'exclude_tags_active': Yup.bool(),
      'refunds_disabled': Yup.bool(),
      'refunds_pre-auth_disabled': Yup.bool(),
      'override_photo-requirement_active': Yup.bool(),
      'override_photo-requirement': Yup.bool(),
      'override_refund-methods_active': Yup.bool(),
      'override_refund-methods': Yup.array().when(['override_refund-methods_active'], {
        is: (active) => active,
        then: (schema) => schema.min(1, 'Select at least 1 refund method')
      }),
      'override_payment-methods_active': Yup.bool(),
      'override_payment-methods': Yup.array(),
      'override_request-types_active': Yup.bool(),
      'override_request-types': Yup.array().when(['override_request-types_active'], {
        is: (active) => active,
        then: (schema) => schema.min(1, 'Select at least 1 request type')
      }),
    }),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      name: record?.data.name ?? '',
      rules: record?.data.rules ?? {
        operator: 'and', rules: [],
      },
      is_active: record?.data.is_active ?? true,
      settings: {
        'exclude_tags_active': record?.data.settings['exclude_tags_active'] ?? false,
        'refunds_disabled': record?.data.settings['refunds_disabled'] ?? false,
        'override_photo-requirement_active': record?.data.settings['override_photo-requirement_active'] ?? false,
        'override_photo-requirement': record?.data.settings['override_photo-requirement'] ? `${record?.data.settings['override_photo-requirement']}` : 'false', // Needs to be a string due to Segmented Control
        'refunds_pre-auth_disabled': record?.data.settings['refunds_pre-auth_disabled'] ?? false,
        'override_refund-methods_active': record?.data.settings['override_refund-methods_active'] ?? false,
        'override_refund-methods': record?.data.settings['override_refund-methods'] ?? [],
        'override_payment-methods_active': record?.data.settings['override_payment-methods_active'] ?? false,
        'override_payment-methods': record?.data.settings['override_payment-methods'] ?? [],
        'override_request-types_active': record?.data.settings['override_request-types_active'] ?? false,
        'override_request-types': record?.data.settings['override_request-types'] ?? [],
      }
    },
    onValuesChange: (values, previous) => {
      const hasRulesChanged = !isEqual(values.rules, previous.rules);

      if (hasRulesChanged) {
        const { hasError } = form.validateField('rules');

        if (!hasError && values.rules?.rules) {
          previewCount(values.rules);
        } else if (!values.rules?.rules.length) {
          reset();
        }
      }
    },
  });

  const previewCount = useDebouncedCallback((rules) => mutate({
    url: `${apiUrl}/tag_group/preview/matches`,
    method: 'post',
    values: { rules, id: record?.data.id }
  }, {
    onError: ({ response }) => {
      setFormErrorsFromResponse(response, form);
    },
  }), 500);

  useEffect(() => {
    if (record?.data.rules) {
      previewCount(record.data.rules);
    }
  }, [record?.data]);

  return <ResourceForm form={form}>
    <Group mb="lg">
      <Title order={2}>Equipment Group</Title>
    </Group>

    <Tabs variant="outline" defaultValue="details">
      <Tabs.List>
        <Tabs.Tab value="details">Details</Tabs.Tab>
        <Tabs.Tab value="settings">Settings</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="details" pt="xs">
        <Card shadow="sm" radius="sm">
          <Card.Section withBorder p="md">
            <Stack>
              <TextInput label="Name" { ...form.getInputProps('name') } />

              <TagGroupRules />

              { (matches?.data || isLoading) && <>
                <CallOut>
                  { isLoading && <Center>
                    <Loader size="sm" />
                  </Center>}

                  { matches?.data && <>
                    { matches?.data.filter(v => v.name).map((v, i) => <Text size="sm" key={i} mb="xs">
                      Found <strong>{v.count}</strong> items already being matched by group <strong>{v.name}</strong>
                    </Text>)}

                    <Text size="sm">
                      Found <strong>{ matches.data[matches.data.length - 1]?.count ?? 0 }</strong> item matches for current group rules.
                    </Text>
                  </>}
                </CallOut>
              </> }

              <Divider />

              <SimpleGrid cols={{ sm: 2 }} spacing="sm">
                <Switch label="Enabled"
                        description="Marks this group as enabled or disabled."
                        offLabel="No" onLabel="Yes"
                        { ...form.getInputProps('is_active', { type: 'checkbox' }) }
                />
              </SimpleGrid>
            </Stack>
          </Card.Section>

          <Card.Section px="md" py="sm">
            <Group justify="right">
              <ResourceForm.CancelButton />
              <ResourceForm.SubmitButton />
            </Group>
          </Card.Section>
        </Card>
      </Tabs.Panel>

      <Tabs.Panel value="settings" pt="xs">
        <Card shadow="sm" radius="sm">
          <Card.Section withBorder p="md">
            <TagGroupSettings />
          </Card.Section>

          <Card.Section px="md" py="sm">
            <Group justify="right">
              <ResourceForm.CancelButton />
              <ResourceForm.SubmitButton />
            </Group>
          </Card.Section>
        </Card>
      </Tabs.Panel>
    </Tabs>
  </ResourceForm>
}
