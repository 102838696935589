import { Button, Card, Divider, Grid, Group, Select, Stack, Text, Title } from '@mantine/core';
import { getCountryTimezone } from '@components/ui/timezones';
import { useIdentity } from '@components/data/Identity.context';
import { Scheduler } from './Scheduler';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IVoiceMessageSettingRecord } from '@interfaces';
import { StatusSelect } from '@components/ui/form/StatusSelect';

export const VoiceMessageScheduleForm = () => {
  const { form } = useResourceForm<IVoiceMessageSettingRecord>();
  const { identity } = useIdentity();

  return <Grid>
    <Grid.Col span={{ lg: 6, md: 7 }}>
      <Stack>
        <Stack gap={2}>
          <Title order={6}>Default Schedule Setting</Title>
          <Text size="sm">Select the setting used by default when schedule is operational.</Text>

          <StatusSelect mt={5}
                  w={125}
                  options={[
                    {value: 'Active', label: 'Active'},
                    {value: 'Inactive', label: 'Inactive'},
                  ]}
                  {...form.getInputProps(`schedule_settings.type`)}
          />
        </Stack>

        <Stack gap={2}>
          <Title order={6}>Schedule</Title>
          <Text size="sm">Add changes to the default setting.</Text>

          <Select
            mt={5}
            w={325}
            searchable
            nothingFoundMessage="Nothing found"
            data={getCountryTimezone(identity?.owner.country_code) as any}
            {...form.getInputProps(`schedule_settings.timezone`)}
          />
        </Stack>

        <Scheduler />

        <Divider />

        <Group justify="right">
          <Button variant="light" color="gray" onClick={() => form.reset()}>Cancel</Button>
          <ResourceForm.UpdateButton />
        </Group>
      </Stack>
    </Grid.Col>
    <Grid.Col span={{ lg: 6, md: 5 }}>
      <Card bg="blue.0" radius="sm" mb="sm">
        <Stack gap="sm">
          <Text size="sm">[Configuration helper text goes here.]</Text>
        </Stack>
      </Card>
    </Grid.Col>
  </Grid>;
}
